<template>
	<div class="item-container">
		<div class="team-item d-space-row">
			<div class="d-space-column align-start">
				<span class="f-20 col-g">{{ item.createTime }}</span>
				<span v-if="item.type == 1">{{i18n.type1}}</span>
				<span v-if="item.type == 2">{{i18n.type2}}</span>
				<span v-if="item.type == 3">{{i18n.type3}}</span>
				<span v-if="item.type == 4">{{i18n.type4}}</span>
				<span v-if="item.type == 5">{{i18n.type5}}</span>
				<span v-if="item.type == 6">{{i18n.type6}}</span>
				<span v-if="item.type == 7">{{i18n.type7}}</span>
				<span v-if="item.type == 8">{{i18n.type8}}</span>
				<span v-if="item.type == 9">{{i18n.type9}}</span>
				<span v-if="item.type == 10">{{i18n.type10}}</span>
				<span v-if="item.type == 11">{{i18n.type11}}</span>
				<span v-if="item.type == 12">{{i18n.type12}}</span>
				<span v-if="item.type == 13">{{i18n.type13}}</span>
			</div>
			<div class="right">
				<span class="f-30" v-if="item.amount > 0">+ {{item.amount}}</span>
				<span class="f-30" v-else> {{item.amount}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			},
			time:{
				type:String,
				default:''
			},
			label:{
				type:String,
				default:''
			},
			value:{
				type:String,
				default:''
			},
		},
		data() {
			return {};
		},
		computed: {
			i18n() {
				return this.$t('my')
			}
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.item-container {
		width: 100%;

		.right {
			color: #74f800;
		}
	}
</style>