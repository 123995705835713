<template>
	<div class="my-container p-all-20">
		<myHeader :title="title" :height="530">
			<template slot="content">
				<div class="the-content p-left-right">
					<div class="list-box p-all-20 m-bottom">
						<div class="stat-list">
							<van-list v-model="loading" :finished="finished" :loading-text="$t('app.loading')"
								:finished-text="$t('app.finished')" @load="loadMore()">
								<template v-for="(item, index) in list">
									<div class="m-bottom">
										<MyBalanceItem :item="item" :key="item.id"></MyBalanceItem>
									</div>
								</template>
							</van-list>
						</div>
					</div>
				</div>
			</template>
		</myHeader>
	</div>
</template>

<script>
import { balanceLogs } from '@/api/account.js'
import MyBalanceItem from '../components/myBalanceItem.vue';
export default {
	components: {
		MyBalanceItem
	},
	data() {
		return {
			type: '',

			list: [],
			pageNum: 1,
			pageSize: 10,
			loading: true,
			finished: false,
		};
	},
	computed: {
		title() {
			if (this.type === 1) {
				return 'LELE'+this.i18n.record
			} else if (this.type === 2) {
				return 'ARB'+this.i18n.record
			} else if (this.type === 3) {
				return 'ETH'+this.i18n.record
			}
			return ''
		},
		i18n() {
			return this.$t('my')
		}
	},
	created() {
		const {
			type
		} = this.$route.query
		this.type = Number(type)

		this.getList()
	},
	methods: {
		async getList() {
			
			const { err, res } = await balanceLogs({ pageNum: this.pageNum, pageSize: this.pageSize,coinType :this.type });
			if (err === null) {
				this.list = this.list.concat(res.records);
				let length = res.records.length
				if (length < this.pageSize) {
					this.finished = true;
				} else {
					this.loading = false
				}
			}

		},
		loadMore() {
			this.pageNum++
			this.getList()
		}
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.the-content {
		width: 100%;
	}

	.list-box {
		width: 100%;
		height: calc(100vh - 220px);
		background: rgba(47, 71, 140, 0.4);
		box-shadow: 0px 0px 13px 0px rgba(65, 126, 150, 0.25);
		border-radius: 12px;

		.stat-list {
			border-radius: 8px;
			position: relative;

			.van-list {
				width: 100%;
				height: calc(100vh - 250px);
				overflow: scroll;
			}
		}
	}
}
</style>